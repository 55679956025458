import React from 'react';

const BrandList = [
    {
        image: './images/brand/IIPT-logo.png'
    },
   
    {
        image: './images/brand/Biosphere.png'
    },
    {
        image: './images/brand/world-tourism.png'
    },
    {
        image: './images/brand/world tourism logo.png'
    },
    {
        image: './images/brand/Ecpat-logo-original.jpg'
    },
    {
        image: './images/brand/0PATA-Logo-transparency_BLACK.png'

    },
    {
        image: './images/brand/image-un.png'
    },
   
    {
        image: './images/brand/brand-4.png'
    },
]

const BrandFour = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" style={{maxWidth:"45%"}}/></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandFour;

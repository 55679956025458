import React, { useState } from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import FsLightbox from "fslightbox-react";
import { FiPlay } from "react-icons/fi";
import SectionTitle from "../elements/sectionTitle/SectionTitle";

const PopupContent =  {
    image: "./images/portfolio/nationalanthem.jpg",
    popupLink: [ "./images/timeline/NATIONAL_ANTHEM_1.mp4"],
}

const VideoPage = () => {

    const [toggler, setToggler] = useState(false);


    return (
        <>
            <SEO title="NationaL Board" />
            <Layout>
                <BreadcrumbOne 
                    title="Videos"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Videos"
                />
            <div className="main-content">
            <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                        <div className="content">
                           <div className="row row--15">
                        <div className="col-lg-6 col-md-6 col-12 mt--30">
                            
                            <div className="video-popup icon-center">
                            
                                <video src={"https://skalbucketnew.s3.amazonaws.com/NATIONAL_ANTHEM_1.mp4"} width="100%" height="300" controls="controls" />
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "National Anthem"
                                    description = ""
                                />
                            </div>
                        </div>  

                        <div className="col-lg-6 col-md-6 col-12 mt--30">
                        
                            <div className="video-popup icon-center">
                            
                                <video src={"https://skalbucketnew.s3.amazonaws.com/Skal+Anthem.mp4"} width="100%" height="300" controls="controls"/>
                                <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Skal Anthem"
                                description = ""
                            />
                            </div>
                        </div>  

                        <div className="col-lg-6 col-md-6 col-12 mt--30">
                        
                            <div className="video-popup icon-center">
                            
                                <video src={"https://skalbucketnew.s3.amazonaws.com/Ska%CC%8Al+International+Promotional+Video.mp4"} width="100%" height="300" controls="controls" />
                                <SectionTitle
                            textAlign = "text-center"
                            radiusRounded = ""
                            subtitle = "Skål International Promotional Video"
                            description = ""
                        />
                            </div>
                        </div>  

                        <div className="col-lg-6 col-md-6 col-12 mt--30">
                    
                            <div className="video-popup icon-center">
                           
                                <video src={"https://skalbucketnew.s3.amazonaws.com/Young+Ska%CC%8Al+Promotional+video.mp4"} width="100%" height="300" controls="controls"/>
                                <SectionTitle
                            textAlign = "text-center"
                            radiusRounded = ""
                            subtitle = "Young Skål Promotional video"
                            description = ""
                        />
                            </div>
                        </div>  

                    </div>
                        </div>
                    </div>
        </div>
        </div>
        </Layout>
        </>
    )
}
export default VideoPage;
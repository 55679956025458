import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ContactOne from "../elements/contact/ContactOne";
import ScrollAnimation from "react-animate-on-scroll";





const PastPresidents = () => {
    return (
        <>
            <SEO title="NationaL Board" />
            <Layout>
                <BreadcrumbOne 
                    title="Past Presidents"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Past Presidents"
                />
            <div className="main-content">
            <div className="rwt-team-area rn-section-gap">
        <div className="container">
            <div className="table-responsive">
    <table className="table table-striped table-hover table-bordered">
  <thead className="table-light">
    <tr>
      <th scope="col">#</th>
      <th scope="col">National Committee Of India</th>
      <th scope="col">Skål Club</th>
      <th scope="col">Organisation/ Company</th>
      <th scope="col">Tenure</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>E.F. Gerald</td>
      <td>Delhi</td>
      <td>Informe presentado</td>
      <td>1953/54</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>F. Young</td>
      <td>Delhi</td>
      <td>Informe presentado</td>
      <td>1956/57</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>S.L. Hakim</td>
      <td>Hyderabad</td>
      <td></td>
      <td>1959/60</td>
    </tr>
    <tr>
      <th scope="row">4</th>
      <td>J.S. Palta</td>
      <td></td>
      <td>Air France</td>
      <td>1960/61</td>
    </tr>
    <tr>
      <th scope="row">5</th>
      <td>Allan E. Currimbhoy</td>
      <td></td>
      <td></td>
      <td>1961/62</td>
    </tr>
    <tr>
      <th scope="row">6</th>
      <td>R.K. Narpat Singh</td>
      <td></td>
      <td>B.O.A.C.</td>
      <td>1962/63</td>
    </tr>
    <tr>
      <th scope="row">7</th>
      <td>Inder Sharma</td>
      <td>Kolkata</td>
      <td>Sita World Travel</td>
      <td>1965/70</td>
    </tr>
    <tr>
      <th scope="row">8</th>
      <td>Nari J. Katgara</td>
      <td>Bombay</td>
      <td>Jaena & Co.</td>
      <td>1971/74</td>
    </tr>
    <tr>
      <th scope="row">9</th>
      <td>Dilip K. Mitra</td>
      <td>Kolkata</td>
      <td>British Airways</td>
      <td>1975/80</td>
    </tr>
    <tr>
      <th scope="row">10</th>
      <td>C. Nagendra Prasad</td>
      <td>Chennai</td>
      <td>Tradewings</td>
      <td>1983/85</td>
    </tr>
    <tr>
      <th scope="row">11</th>
      <td>Ghulam Naqshband</td>
      <td>Delhi</td>
      <td>Sita World Travel</td>
      <td>1986/87</td>
    </tr>
    <tr>
      <th scope="row">12</th>
      <td>José Dominic</td>
      <td>Cochin</td>
      <td>Casino Hotel</td>
      <td>1999/01</td>
    </tr>
    <tr>
      <th scope="row">13</th>
      <td>Sanjay Sanghi</td>
      <td>Hyderabad</td>
      <td>Satellite Travels</td>
      <td>2001/02</td>
    </tr>
    <tr>
      <th scope="row">14</th>
      <td>M.S. Kuki Chawla</td>
      <td>Delhi</td>
      <td>Siddhartha Travels</td>
      <td>2002/06</td>
    </tr>
    <tr>
      <th scope="row">15</th>
      <td>Imtiaz Ahmed</td>
      <td>Bangalore</td>
      <td>Airborne Tours & Travels</td>
      <td>2006/08</td>
    </tr>
    <tr>
      <th scope="row">16</th>
      <td>Praveen Chugh</td>
      <td>Delhi</td>
      <td>Travel Services International</td>
      <td>2008/09</td>
    </tr>
    <tr>
      <th scope="row">17</th>
      <td>Jason Samuel</td>
      <td>Bombay</td>
      <td></td>
      <td>2010/12</td>
    </tr>
    <tr>
      <th scope="row">18</th>
      <td>Sabrina Nayudu</td>
      <td>Chennai</td>
      <td></td>
      <td>2012/14</td>
    </tr>
    <tr>
      <th scope="row">19</th>
      <td>Amarjit Lidder</td>
      <td>Pune</td>
      <td>Kaleidoscope Travel Consultants</td>
      <td>2014/16</td>
    </tr>
    <tr>
      <th scope="row">20</th>
      <td>Mario Sequeira</td>
      <td>Goa</td>
      <td>Tonia Estates & Resorts</td>
      <td>2016/18</td>
    </tr>
    <tr>
      <th scope="row">21</th>
      <td>Ranjini Nambiar</td>
      <td>Bangalore</td>
      <td>Footloose Travels</td>
      <td>2018/20</td>
    </tr>
    <tr>
      <th scope="row">22</th>
      <td>Carl Vaz</td>
      <td>Bombay</td>
      <td>Charson Advisory</td>
      <td>2020/24</td>
    </tr>
    <tr>
      <th scope="row">23</th>
      <td>Sanjeev Mehra</td>
      <td>Kolkata</td>
      <td>Aaryan Leisure & Holidays</td>
      <td>2024/26</td>
    </tr>
  </tbody>
</table>
        </div>
        </div>
        </div>
        </div>
        </Layout>
        </>
    )
}
export default PastPresidents;